import React from "react";
import { Route, Routes } from "react-router-dom";
import FormPage from "./pages/FormPage.js";
import LoginPage from "./pages/LoginPage.js";
import HomePage from "./pages/HomePage.js";
import RoutinePage from "./pages/RoutinePage.js";
import SessionPage from "./pages/SessionPage.js";
import SignupPage from "./pages/SignupPage.js";
import { CameraPage } from "./pages/CameraPage.js";

export const App = () => {
  return (
    <Routes>
      <Route path={'/'} element={<HomePage />} />
      <Route path={'/login'} element={<LoginPage />} />
      <Route path={'/signup'} element={<SignupPage />} />
      <Route path={'/form'} element={<FormPage/>} />
      <Route path={'/routine/:id'} element={<RoutinePage />} />
      <Route path={'/session/:id'} element={<SessionPage />} />
      <Route path={'/camera/:id'} element={<CameraPage />} />
    </Routes>
  );
};
