import React from "react";

export const LoginPage = () => {

    return (
        <div>
            TODO
        </div>
    );
};

export default LoginPage;
