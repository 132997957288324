import React, { useEffect, useRef, useState } from "react";
import { Camera } from "react-camera-pro";

export const CameraPage = () => {
    const camera = useRef(null);
    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 7000);
    }, []);

    return (
        <div>
            <Camera ref={camera} facingMode="user" />
            {show &&<div class="absolute bottom-1/3 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-black rounded-lg p-4 text-center">
                Place the camera where it can see you stretching
            </div>}
        </div>
    );
};

// eslint-disable-next-line
function dataURLToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([new Blob([u8arr], {type:mime})], filename, {type: mime});
}
