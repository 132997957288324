import React from "react";

export const Loading = () => {

    const loaderStyle = {
        width: '100px',
        aspectRatio: '1',
        borderRadius: '50%',
        border: '12px solid',
        borderColor: 'red transparent',
        animation: 'l1 1s infinite',
        zIndex: 9999,
      };
    
      const keyframes = `
        @keyframes l1 {
          to { transform: rotate(0.5turn); }
        }
      `;

    return (
        <div style={overlayStyles}>
            <style>
                {keyframes}
            </style>
            <div style={loaderStyle}></div>
        </div>
    );
};

const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    FontFace: "Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9998,
    fontSize: "2rem",
};