import React from "react";

export const HomePage = () => {

    return (
        <div>
            TODO
        </div>
    );
};

export default HomePage;
