const serverUrl = process.env.REACT_APP_SERVER_URL;

export const createRoutine = async (data, callback, errorCallback = null) => {
    fetch(serverUrl + `/create/routine`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({'content-type': 'application/json'})
    })
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((error) => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
};