import React from "react";

export const SignupPage = () => {

    return (
        <div>
            TODO
        </div>
    );
};

export default SignupPage;
