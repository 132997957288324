import React from "react";

export const RoutinePage = () => {

    return (
        <div>
            TODO
        </div>
    );
};

export default RoutinePage;
