import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';


const progression = [
    {
        message: "Place your smartphone where the camera can see you",
        color: "bg-gray-300"
    },
    {
        message: "Ready!",
        color: "bg-green-300"
    },
    {
        message: "Try pulling your right ankle towards your body",
        color: "bg-yellow-300"
    },
    {
        message: "Great form!",
        color: "bg-green-300"
    }
]

const SessionPage = () => {
  const { id } = useParams();
  const videoRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds

  const [progressionIndex, setProgressionIndex] = useState(-1);
  
  // Effect to handle countdown logic
  useEffect(() => {
    if (timeLeft > 0 && progressionIndex >= 1) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft, progressionIndex]);

  // Effect to handle space bar key press
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space') {
        if (progressionIndex === 0) {
        //   setShowQRCode(false);
        //   setSquareMessage('Ready!');

        //   setTimeout(() => {
            // setSquareMessage('Ready!');
            // Wait 3 seconds before playing the video
            // setTimeout(() => {
        document.getElementById('video').play();
            // videoRef.current.play();
            // }, 1000);
        //   }, 0);
        } 
        setProgressionIndex(progressionIndex + 1);
        //   setReady(false); // Reset ready state
        //   setShowQRCode(true);
        //   setSquareMessage('Place your smartphone where the camera can see you');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    }; 
  }, [progressionIndex]);

  // Function to format the time into minutes and seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Calculate the width percentage for the progress bar
  const progressBarWidth = ((600 - timeLeft) / 600) * 100;

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-tr from-pink-500 to-orange-500">
      <div className="bg-white rounded-3xl w-11/12 h-5/6 flex flex-col items-center p-8 relative">
        <div className="absolute top-0 left-0 h-2 px-6 bg-black" style={{ width: `${progressBarWidth}%` }}></div>
        <span className="text-black text-3xl font-bold mb-8">{formatTime(timeLeft)}</span>
        {progressionIndex === -1 && <h1 className="text-black text-3xl font-bold mb-8">Scan the QR code or press 'play' to begin your session.</h1>}
        <div className="flex flex-1 w-full justify-center items-center  space-x-8">
          <div className="flex flex-col items-center justify-center w-2/3">
            <video id="video" src='https://click-stretch-assets.s3.us-west-1.amazonaws.com/QuadStretch.mov' ref={videoRef} className="w-full h-9/10 object-contain" />
            <h2 className="text-black text-2xl font-semibold mt-4 mb-1">1. Quadricep Stretch (1:30)</h2>
            <span className="text-slate-400 text-sm italic">CC Attribution</span>
          </div>
          <div className="flex flex-col items-center justify-center w-1/4 h-1/2">
            {progressionIndex === -1 ? (
                <QRCode value={`${"https://www.clickstretch.com"}/camera/${id}`} size={128} className='w-full h-full object-contain' />
            ) : (
              <div className={`flex items-center justify-center h-full aspect-square rounded-lg text-center px-4 my-auto ${progression[progressionIndex].color}`}>
                <span className="text-black font-semibold text-4xl">{progression[progressionIndex].message}</span>
              </div>
            )}
            {/* <h2 className="text-black text-2xl font-bold my-4">Live feedback</h2> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionPage;
