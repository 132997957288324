import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRunning,
  faBiking,
  faTableTennis,
  faGolfBall,
  faSwimmer,
  faHiking,
  faTshirt,
  faBaseballBall,
  faVolleyballBall,
} from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../components/Loading';
import { createRoutine } from '../api';


export const FormPage = () => {

    const navigate = useNavigate();
    const firstButtonRef = useRef(null);
    const [selectionMade, setSelectionMade] = useState(false);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      if (firstButtonRef.current) {
        firstButtonRef.current.focus();
      }
    }, [selectionMade]);


  // TODO add webos back button callback

  const submit = () => {
    setLoading(true);
    let data = { activity: "running", duration: 10 }; // TODO
    createRoutine(data, (routine) => {
      navigate(`/session/${routine.session_id}`);
      setLoading(false);
    });
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-tr from-pink-500 to-orange-500">
      <div className="bg-white rounded-3xl w-11/12 h-5/6 flex flex-col justify-between items-center p-8">
        <h1 className="text-black text-4xl font-bold mb-8">
            {selectionMade ? 'How long of a session do you want?' : 'Choose related activity'}
        </h1>
        {loading && <Loading />}
        {selectionMade ? (
          <div className="flex flex-col gap-4 w-full max-w-2xl h-full justify-center items-center">
          {sessionDurations.map((duration, index) => (
            <button
              key={index}
              className="bg-gray-200 text-black font-bold text-3xl p-10 rounded-lg h-12 flex items-center justify-center w-full focus:outline-none focus:border-8 focus:border-black"
              tabIndex={0}
              ref={index === 0 ? firstButtonRef : null}
              onClick={submit}
            >
              {duration}
            </button>
          ))}
        </div>
        
        ) : (
        <div className="grid grid-cols-3 gap-4 w-full h-full max-w-2xl">
          {activities.map((activity, index) => (
            <button
              key={activity.name}
              className="bg-gray-200 rounded-lg flex flex-col items-center justify-center aspect-square text-black text-2xl focus:outline-none focus:border-8 focus:border-black"
              tabIndex={0}
              ref={index === 0 ? firstButtonRef : null}
              onClick={() => setSelectionMade(true)}
            >
              <div className="mb-2">
                <FontAwesomeIcon icon={activity.icon} size="2x" />
              </div>
              <span className="font-semibold">{activity.name}</span>
            </button>
          ))}
        </div> )
        }
      </div>
    </div>
  );
};

const activities = [
    { name: 'General', icon: faTshirt },
    { name: 'Running', icon: faRunning },
    { name: 'Biking', icon: faBiking },
    { name: 'Tennis', icon: faTableTennis },
    { name: 'Golf', icon: faGolfBall },
    { name: 'Swimming', icon: faSwimmer },
    { name: 'Hiking', icon: faHiking },
    { name: 'Baseball', icon: faBaseballBall },
    { name: 'Volleyball', icon: faVolleyballBall },
  ];

  const sessionDurations = ['10 minutes', '20 minutes', '30 minutes'];


export default FormPage;